import React from 'react';
import './App.css';

function App() {
  // iPhone 15 Pro dimensions
  const iphoneWidth = 340;  // Scaled up from original
    const iphoneHeight = 606; // Scaled up to maintain aspect ratio

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-content">
          <div className="App-text-content">
            <h1 className="App-title">Recio - Your Smart Receipt Manager</h1>
            <p className="App-description">
            Effortlessly capture, organize, and export receipts with Recio. Powered by advanced edge detection and text recognition, Recio automates the receipt scanning process. 
            </p>
            {/* <p className="App-companion">Breathe Smarter with AI-Powered Air Quality Insights</p> */}
            <div className="App-buttons">
              <a href="https://apps.apple.com/us/app/recio-scan-expense-receipts/id6736882285" target="_blank">
                <img src="/appStore.svg" alt="Download on the App Store" />
              </a>
            </div>
            <div className="App-footer">
              <a href="mailto:hello@recio.app">hello@recio.app</a>
              <span className="App-separator">|</span>
              <a href="https://docs.google.com/document/d/1CnmmgpB9MG7k9-bpjBcB_8kyJ3NWdZ4YOfoScWOXCAk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">privacy</a>
              <span className="App-separator">|</span>
              <a href="https://docs.google.com/document/d/1-qCWLLURClJqjB5-FW2w2R70NzMo8a28kwAiEeI7Pzk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">terms</a>
            </div>
          </div>
          <div className="App-iphone-container">
            <div className="App-iphone" style={{
              width: `${iphoneWidth}px`,
              height: `${iphoneHeight}px`,
              maxWidth: '100%',
              maxHeight: '80vh'
            }}>
              <div className="App-iphone-notch"></div>
              <img 
                src="/screen.png" 
                alt="Air Quality App Screenshot" 
                className="App-iphone-screen"
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;